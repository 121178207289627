<style>

  img {
    display: block;
    margin: 0 auto;
    width: 90%;
  }

  footer {
    padding: 15px;
    font-size: 0.65rem;
    text-align: center;
    text-shadow: 0 0 20px lime;
  }
</style>

<img src="/images/flame-bday.gif" alt="Happy Brithday Flames" />

<footer>
  Created by Dave in 2021
</footer>
